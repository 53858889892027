var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "体检记录基本信息",
                  "head-btn-options": _vm.headTopBtnOptions,
                },
                on: {
                  "head-cancel": _vm.handleHeadGoback,
                  "head-save": function ($event) {
                    return _vm.headSave(1)
                  },
                  "head-submit": function ($event) {
                    return _vm.headSave(2)
                  },
                },
              }),
              _c(
                "avue-form",
                {
                  ref: "formLayout",
                  staticStyle: { padding: "10px" },
                  attrs: { option: _vm.formOptions },
                  model: {
                    value: _vm.dataForm,
                    callback: function ($$v) {
                      _vm.dataForm = $$v
                    },
                    expression: "dataForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "hcCode" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "calc(100% - 120px)" },
                        attrs: {
                          maxlength: "100",
                          disabled: _vm.type == "view",
                        },
                        model: {
                          value: _vm.dataForm.hcCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "hcCode", $$v)
                          },
                          expression: "dataForm.hcCode",
                        },
                      }),
                      _c("el-switch", {
                        attrs: {
                          "active-text": "自动生成",
                          disabled: _vm.type == "view",
                        },
                        on: { change: _vm.handleSwidth },
                        model: {
                          value: _vm.generate,
                          callback: function ($$v) {
                            _vm.generate = $$v
                          },
                          expression: "generate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "userName" },
                    [
                      _c("el-input", {
                        ref: "userName",
                        attrs: { disabled: _vm.type == "view" },
                        on: { focus: _vm.changeUser },
                        model: {
                          value: _vm.dataForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "userName", $$v)
                          },
                          expression: "dataForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type !== "add"
        ? [
            _c(
              "el-tabs",
              {
                attrs: { type: "border-card" },
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "结果附件", name: "files" } },
                  [
                    _c("head-layout", { attrs: { "head-title": "体检报告" } }),
                    _c(
                      "div",
                      { staticClass: "imgContainer" },
                      [
                        _vm.type == "view"
                          ? _vm._l(_vm.fileList, function (item, index) {
                              return _c("el-image", {
                                key: index,
                                staticStyle: {
                                  width: "146px",
                                  height: "146px",
                                  margin: "0 9px 8px 1px",
                                },
                                attrs: {
                                  src: item.url,
                                  "preview-src-list": _vm.fileList.map(
                                    (val) => val.url
                                  ),
                                },
                              })
                            })
                          : _c(
                              "el-upload",
                              {
                                attrs: {
                                  action:
                                    "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                  headers: _vm.headers,
                                  "list-type": "picture-card",
                                  "on-success": _vm.handleFileCoverSuccess,
                                  "before-upload": _vm.beforeUpload,
                                  accept: ".jpg,.png",
                                  "file-list": _vm.fileList,
                                  "on-remove": _vm.onRemove,
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        图片支持*.jpg/*.png格式,文件大小不超过5M\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "结果明细", name: "table" } },
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-title": "",
                        "head-btn-options": _vm.headBtnOptions,
                      },
                      on: {
                        "head-add": _vm.headAdd,
                        "head-export": _vm.headExport,
                        "head-import": _vm.headImport,
                        "head-del": _vm.rowRemove,
                      },
                    }),
                    _c("grid-layout", {
                      ref: "gridLayOut",
                      attrs: {
                        "grid-row-btn": _vm.gridRowBtn,
                        "table-options": _vm.tableOptions,
                        "table-data": _vm.tableData,
                        "table-loading": _vm.tableLoading,
                        editType: _vm.inline,
                      },
                      on: {
                        "gird-handle-select-click": _vm.selectionChange,
                        "row-edit": _vm.rowEdit,
                        "row-remove": _vm.rowRemove,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.dialogVisible,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.cedDialog,
            title: _vm.title,
            "before-close": _vm.handleCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cedDialog = $event
            },
          },
        },
        [
          _c("avue-form", {
            ref: "cedForm",
            attrs: { option: _vm.dialogOption },
            on: { submit: _vm.handleSubmit },
            model: {
              value: _vm.detailForm,
              callback: function ($$v) {
                _vm.detailForm = $$v
              },
              expression: "detailForm",
            },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设备分类导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "import" },
            [
              _vm.excelBox
                ? _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }