<template>
    <div>
      <el-container>
        <el-main>
          <head-layout
            head-title="体检记录基本信息"
            :head-btn-options="headTopBtnOptions"
            @head-cancel="handleHeadGoback"
            @head-save="headSave(1)"
            @head-submit="headSave(2)"
          />
          <avue-form :option="formOptions" ref="formLayout" v-model="dataForm" style="padding:10px;" >
            <template slot="hcCode">
                <el-input v-model="dataForm.hcCode" maxlength="100" style="width: calc(100% - 120px)" :disabled="type == 'view'"></el-input>
                <el-switch v-model="generate" active-text="自动生成" @change="handleSwidth" :disabled="type == 'view'"></el-switch>
            </template>
            <template slot="userName">
              <el-input v-model="dataForm.userName" ref="userName" @focus="changeUser" :disabled="type == 'view'"></el-input>
            </template>
          </avue-form>

        </el-main>
      </el-container>
      <template v-if="type!=='add'">
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="结果附件" name="files">
                <head-layout head-title="体检报告"/>
                <div class="imgContainer">
                  <template v-if="type == 'view'">
                    <el-image
                      v-for="(item, index) in fileList"
                      :key="index"
                      :src="item.url"
                      style="width: 146px; height:146px; margin: 0 9px 8px 1px;"
                      :preview-src-list="fileList.map(val=>val.url)"
                      ></el-image>
                  </template>
                  <el-upload
                        v-else
                        action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                        :headers="headers"
                        list-type="picture-card"
                        :on-success="handleFileCoverSuccess"
                        :before-upload="beforeUpload"
                        accept=".jpg,.png"
                        :file-list="fileList"
                        :on-remove="onRemove"
                    >
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">
                            图片支持*.jpg/*.png格式,文件大小不超过5M
                        </div>
                    </el-upload>
                </div>
            </el-tab-pane>
            <el-tab-pane label="结果明细" name="table">
                <head-layout
                    head-title=""
                    :head-btn-options="headBtnOptions"
                    @head-add="headAdd"
                    @head-export="headExport"
                    @head-import="headImport"
                    @head-del="rowRemove"
                />
                <grid-layout
                  ref="gridLayOut"
                  :grid-row-btn="gridRowBtn"
                  :table-options="tableOptions"
                  :table-data="tableData"
                  :table-loading="tableLoading"
                  :editType="inline"
                  @gird-handle-select-click="selectionChange"
                  @row-edit="rowEdit"
                  @row-remove="rowRemove"
                  >
              </grid-layout>
            </el-tab-pane>
        </el-tabs>
      </template>
      <el-dialog title="人员选择" :visible.sync="dialogVisible" width="80%" top="8vh">
        <UserDetpDialog ref="UserDetpDialog" @select-data="selectData" />
      </el-dialog>
      <!-- 操作页 -->
      <el-dialog :visible.sync="cedDialog" :title="title" :before-close="handleCancel">
            <avue-form :option="dialogOption" ref="cedForm" v-model="detailForm" @submit="handleSubmit"></avue-form>
            <template slot="footer">
                <el-button size="small" @click="handleCancel()" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
            </template>
      </el-dialog>
      <!-- 导入 -->
      <el-dialog
          title="设备分类导入"
          append-to-body
          :visible.sync="excelBox"
          width="555px"
          >
          <div class="import">
              <avue-form
              :option="excelOption"
              :upload-after="uploadAfter"
              v-if="excelBox"
              >
              <template slot="excelTemplate">
                  <el-button type="primary" @click="handleTemplate">
                  {{
                      $t("cip.plat.sys.user.field.download")
                  }}<i class="el-icon-download el-icon--right"></i>
                  </el-button>
              </template>
              </avue-form>
          </div>
      </el-dialog>
    </div>
  </template>

  <script>
  import FormLayout from "@/views/components/layout/form-layout";
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import { getPageCode } from '@/api/system/serialNumber'
  import { mapGetters } from "vuex";
  import { ohhcSubmit, ohhcDetail, ohhcDetailList, ohhcDetailSubmit, ohhcDetailRemove } from '@/api/healthManage/testRecords'
  import {getToken} from "@/util/auth";
  import {exportBlob} from "@/api/common";
  import {downloadXls} from "@/util/util";
  import NProgress from 'nprogress';
  import {dateFormat} from "@/util/date";
  import UserDetpDialog from '@/views/components/UserDeptDialog/UserDetpDialog'

  export default {
    components: {
      FormLayout,
      HeadLayout,
      GridLayout,
      UserDetpDialog,
    },

    data() {
      return {
        nodeData: {},
        type: "",
        dataForm: {
          hcCode:'',
          hcType:'',
          hcDate:'',
          userName:'',
          userId:'',
          deptName:'',
          deptId: '',
          hcOrg:'',
          remark:'',
        },
        generate: true,
        dialogVisible: false,
        activeName: 'files',
        fileList: [],
        tableData: [],
        tableLoading: false,
        selectionList: [],
        cedDialog: false,
        title: '',
        dialogLoading: false,
        detailForm: {
          indexName: '',
          standardVal: '',
          checkVal: ''
        },
        // 导入
        excelBox:false,
        excelOption: {
            submitBtn: false,
            emptyBtn: false,
            column: [
            {
                label: this.$t("cip.plat.sys.user.field.excelFile"),
                prop: 'excelFile',
                type: 'upload',
                drag: true,
                loadText: this.$t("cip.plat.sys.user.field.waiting"),
                span: 24,
                propsHttp: {
                    res: 'data'
                },
                tip: this.$t("cip.plat.sys.user.field.format"),
                data: {hcId: ''},
                action: "/api/sinoma-hse-prj/ohhcdetail/import",
            },
            {
                label: this.$t("cip.plat.sys.user.field.excelTemplate"),
                prop: 'excelTemplate',
                formslot: true,
                span: 24,
            }
            ]
        },
      };
    },

    created() {
      this.nodeData = this.$route.query.row ? JSON.parse(this.$route.query.row) : {};
      this.type = this.$route.query.type;
      const orgId = this.userInfo.dept_id
      const orgCode = this.userInfo.deptCode ? this.userInfo.deptCode : ''
      if (this.nodeData.id){
        this.getDetail(this.nodeData.id)
        this.excelOption.column[0].data = {
          hcId: this.nodeData.id,
        }
      } else {
        this.dataForm.orgId = orgId
        this.dataForm.orgCode = orgCode
        this.handleSwidth(this.generate)
        this.dataForm.hcDate = dateFormat(new Date(), "yyyy-MM-dd")
        if(this.nodeData.userId) {
          this.dataForm.userId = this.nodeData.userId
          this.dataForm.userName = this.nodeData.userName
          this.dataForm.deptName = this.nodeData.deptName
          this.dataForm.deptId = this.nodeData.deptId
        }
      }
    },

    mounted () {
      if(this.type == 'view'){
        this.$refs.formLayout.allDisabled = true
      }
    },

    computed: {
      ...mapGetters(["permission", "colorName", "userInfo"]),
      headers: function () {
        return {"Sinoma-Auth": getToken()};
      },
    //   permissionList() {
        // return {
        //   addBtn: this.vaildData(this.permission.eduquestions_add, true),
        //   viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        //   editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        //   delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        // };
    //   },
      formOptions() {
        return {
          size: "small",
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 130,
          column: [
            {
              label: "记录编号",
              prop: "hcCode",
              placeholder: "请填写",
              span: 8,
              formslot: true,
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: ["blur", "change"],
                },
                {
                  max: 120,
                  message: "文件名称不能超过120个字符",
                  trigger: "blur",
                },
              ],
            },
            {
                label: "体检类型",
                prop: "hcType",
                placeholder: "请选择",
                span: 8,
                type: "select",
                dicUrl:
                "/api/sinoma-system/dict-biz/dictionary-tree?code=oh_hc_type",
                props: {
                label: "dictValue",
                value: "dictKey",
                },
                rules: [
                {
                    required: true,
                    message: "请选择",
                    trigger: "change",
                },
                ],
            },
            {
              label: "体检日期",
              prop: "hcDate",
              placeholder: "请选择",
              span: 8,
              type: "date",
              format: "yyyy-MM-dd", // 设置日期格式
              valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: "change",
                },
              ],
            },
            {
              label: "体检人",
              prop: "userName",
              placeholder: "请填写",
              span: 8,
              formslot:true,
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: "change",
                },
                {
                  max: 120,
                  message: "文件编号不能超过120个字符",
                  trigger: "change",
                },
              ],
            },
            {
              label: "所在部门",
              prop: "deptName",
              placeholder: "请先选择体检人",
              span: 8,
              disabled: true,
            },
            {
              label: "体检机构",
              prop: "hcOrg",
              placeholder: "请输入体检机构名称",
              maxlength: 255,
              span: 8,
              rules: [
                {
                    required: true,
                    message: '请输入体检机构名称',
                    trigger: 'blur'
                }
              ]
            },
            {
              label: "检查结论",
              prop: "remark",
              placeholder: "请填写",
              type: 'textarea',
              span: 24,
              maxlength:255,
              showWordLimit: true,
            },
          ],
        };
      },
      headTopBtnOptions() {
        let buttonBtn = [];
        if (this.type != "view") {
          buttonBtn.push({
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: "save",
          });
          buttonBtn.push({
            label: '提交',
            emit: "head-submit",
            type: "button",
            icon: "",
            btnOptType: "submit",
          });
        }
        buttonBtn.push({
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
        return buttonBtn;
      },

      tableOptions () {
          return{
              customRowAddBtn: false,
              menuWidth: 260,
              menu: this.type == 'view'? false: true,
              selection: this.type == 'view'? false: true,
              index: true,
              indexLabel: '序号',
              column: [
                  {
                      label: "指标项",
                      prop: "indexName",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "参考值",
                      prop: "standardVal",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "检测值",
                      prop: "checkVal",
                      align: "center",
                      overHidden: true,
                  },
              ],
          }
      },

      headBtnOptions () {
        let btnList = [
            {
                label: this.$t("cip.cmn.btn.addBtn"),
                emit: "head-add",
                type: "button",
                icon: "",
            },
            {
                label: this.$t("cip.cmn.btn.exportBtn"),
                emit: "head-export",
                type: "button",
                icon: "",
            },
            {
                label: this.$t("cip.cmn.btn.importBtn"),
                emit: "head-import",
                type: "button",
                icon: "",
            },
            {
                label: this.$t("cip.cmn.btn.delBtn"),
                emit: "head-del",
                type: "button",
                icon: "",
            },
        ]
        return this.type == 'view' ? [] : btnList
      },

      dialogOption () {
            return {
                size: 'small',
                submitBtn: false,
                emptyBtn: false,
                labelWidth: 120,
                column: [
                    {
                        label: "指标项",
                        prop: "indexName",
                        span: 12,
                        rules: [{
                            required: true,
                            message: '请填写指标项',
                            trigger: 'bulr'
                        }]
                    },
                    {
                        label: "标准值",
                        prop: "standardVal",
                        span: 12,
                    },
                    {
                        label: "检测值",
                        prop: "checkVal",
                        span: 12,
                        maxlength: 255,
                        rules: [{
                            required: true,
                            message: '请输入条件结果值',
                            trigger: ['blur']
                        }]
                    }
                ]
            }
      },

      gridRowBtn () {
        let btnList = [
          {
            label: this.$t("cip.cmn.btn.editBtn"),
            emit: "row-edit",
            type: "button",
            icon: "",
            btnOptType: "edit",
          },
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "row-remove",
            type: "button",
            icon: "",
            btnOptType: "del",
          }
        ]
        return btnList
      }
    },

    methods: {
      getDetail(id) {
        ohhcDetail({ id }).then((res) => {
          const data = res.data.data;
          this.dataForm = data;
          this.fileList = data.files ? data.files.split(',').map(val=>{
            let pos = val.lastIndexOf(".")
            return {
                name: val.substring(pos,val.length),
                url: val,
            }
          }) : []
          this.onLoad()
        });
      },

      handleHeadGoback() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },
      // 保存
      headSave(val) {
        this.$refs.formLayout.$refs.form.validate((valid) => {
          if (valid) {
            if(val == 2) {
              this.dataForm.hcStatus = 'finished'
            }
            this.dataForm.files = this.fileList.map(val => {
                return val.url
            }).toString()
            ohhcSubmit({ ...this.dataForm }).then((res) => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
                this.$router.$avueRouter.closeTag();
                this.$loading().close();
                if(val == 1 && !this.nodeData.id){
                  this.$router.replace({
                    path: "/business/healthManage/testRecords/form",
                    query: {
                      row: JSON.stringify({ id: res.data.data.id }),
                      type: "edit",
                    },
                  });
                }
                if (val == 2) {
                  this.handleHeadGoback();
                }
            });
          }
        });
      },

      handleSwidth (val) {
        if(val) {
            getPageCode({ruleCode: 'oh_hc_code'}).then(res=>{
              if(res.data.data){
                    this.dataForm.hcCode = res.data.data
                }
            })
        }
      },

      // 人员选择
      changeUser () {
        this.dialogVisible = true
        this.$refs.userName.blur()
      },

      selectData(row,deptId,deptName){
            this.dataForm.userId = row.id
            this.dataForm.userName = row.realName
            this.dataForm.deptId = deptId
            this.dataForm.deptName = deptName
            this.dialogVisible = false
        },

        /**
         * @des 体检结果相关
         */

         onLoad() {
          this.tableLoading = true;
            ohhcDetailList({hcId: this.dataForm.id}).then(res => {
                const data = res.data.data;
                // this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data;
                this.tableLoading = false;
            });
        },

        handleClick () {
        },

        handleFileCoverSuccess (response, file,files) {
            this.fileList = files.map(res => {
                if(res.response){
                    return {
                        name: res.name,
                        url: res.response.data.link
                    }

                } else {
                    return {
                        name: res.name,
                        url: res.url
                    }
                }
            })
        },
        beforeUpload (file){
            const isLt2M = file.size / 1024 / 1024 < 5;
            const isTopLimit = this.fileList.length >= 9
            if (isTopLimit) {
              this.$message.error(`当前限制最多可以上传9个文件`)
              return false
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            return isLt2M
        },

        onRemove (file,fileList) {
          console.log(file,fileList)
          this.fileList = this.fileList.filter(val=>val.url!=file.url)
        },

        headAdd () {
            this.cedDialog = true
            this.detailForm.hcId = this.dataForm.id
            this.detailForm.orgId = this.userInfo.dept_id
            this.detailForm.orgCode = this.userInfo.deptCode
            this.title = '结果明细新增'
        },
        headExport () {
          this.$confirm("是否导出全部结果明细?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            NProgress.start();
            // exportBlob(`/api/sinoma-hse-prj/ohhcdetail/export?${this.website.tokenHeader}=${getToken()}`).then(res => {
            exportBlob(`/api/sinoma-hse-prj/ohhcdetail/export?hcId=${this.dataForm.id}`).then(res => {
              downloadXls(res.data, `${this.dataForm.userName}的体检结果明细.xlsx`);
              NProgress.done();
            })
          });
        },

        rowRemove (row) {
          let ids = []
            if(row) {
                ids = [row.id]
            } else {
                ids = this.selectionList.map(val=>val.id)
                if(ids.length == 0){
                    this.$message.warning('请选择操作数据')
                }
            }
            if(ids){
                this.$confirm("确认删除当前数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                  ohhcDetailRemove(ids).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功")
                            this.onLoad();
                        }
                    });
                }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
            }
        },
        rowEdit (row,index) {
          for(let key in this.detailForm) {
                if(key in row){
                    this.detailForm[key] = row[key]
                } else {
                    this.detailForm[key] = ''
                }
            }
            this.detailForm.id = row.id
            this.cedDialog = true
        },

        selectionChange (val) {
            this.selectionList = val
        },

        handleCancel () {
            this.$refs.cedForm.resetForm()
            delete this.detailForm.id
            this.cedDialog = false
        },
        handleSubmit (data,done) {
            this.$refs.cedForm.validate(valid=>{
                if(valid) {
                    this.dialogLoading = true
                    ohhcDetailSubmit(this.detailForm).then(() => {
                        this.onLoad()
                        this.$message.success('操作成功')
                        this.dialogLoading = false
                        this.handleCancel()
                        done()
                    }).catch(err => {
                        done()
                    }).finally(() => {
                        this.dialogLoading = false
                    })
                }
            })
        },
        handleSave () {
            this.$refs.cedForm.submit();
        },

        headImport () {
            this.excelBox = true
        },

        uploadAfter(res, done, loading, column) {
            this.excelBox = false;
            this.onLoad()
            done();
        },
        handleTemplate() {
            exportBlob(`/api/sinoma-hse-prj/ohhcdetail/exportTemplate?hcId=${this.dataForm.id}`).then(res => {
                downloadXls(res.data, "体检记录结果明细模板.xlsx");
            })
        },
    }

  };
  </script>

  <style lang="scss" scoped>
    // ::v-deep .switch-inputinput{
    //   width: calc(100% - 120px);
    // }
    .el-switch{
        margin-left: 10px;
    }
    .imgContainer{
        background-color: #fff;
        padding: 12px;
        // min-height: calc(100vh - 500px) !important;
    }
  </style>
